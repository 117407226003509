var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.photoDetails)?_c('div',{staticClass:"th-body"},[_c('modal',{attrs:{"name":"singlePictureModal","width":"100%","height":"100%","classes":"picture-wrp"}},[_c('div',{attrs:{"slot":"top-right"},slot:"top-right"},[_c('b-icon',{staticClass:"closeBtn",attrs:{"icon":"x"},on:{"click":function($event){return _vm.$modal.hide('singlePictureModal')}}})],1),_c('swiper',{staticClass:"swiper",attrs:{"options":_vm.swiperOption}},[_c('div',{staticClass:"th-visit-wrp--child---xray special"},[_c('swiper-slide',[_c('image-portrait',{attrs:{"showFullscreenButton":false,"propsUrl":_vm.photoDetails.Base64Photo}},[[_c('round-label-with-check',{staticClass:"th-visit-wrp--child---xray-lbl",attrs:{"initialStrProps":_vm.photoDetails.Position === 1
            ? 'GF' : _vm.photoDetails.Position === 2
            ? 'LS' : _vm.photoDetails.Position === 3
            ? 'RS' : null,"checkSign":false,"initialDescStrProps":null,"labelAlert":_vm.photoDetails.LabelLevel === 1
            ? 3 : _vm.photoDetails.LabelLevel === 2
            ? 1 :  _vm.photoDetails.LabelLevel === 3
            ? 0 : null}})]],2)],1)],1),_c('div',{staticClass:"swiper-scrollbar",attrs:{"slot":"scrollbar"},slot:"scrollbar"})])],1),_c('div',{staticClass:"th-body--img"},[_c('image-portrait',{staticClass:"special-arrow-case",attrs:{"propsphotoId":_vm.photoId,"propsUrl":_vm.photoDetails.Base64Photo,"enableZoomEffect":true},on:{"fullScreenImageEmit":_vm.callFscreen}},[[_c('round-label-with-check',{staticClass:"th-visit-wrp--child---xray-lbl",attrs:{"initialStrProps":_vm.photoDetails.Position === 1
            ? 'GF' : _vm.photoDetails.Position === 2
            ? 'LS' :  _vm.photoDetails.Position === 3
            ? 'RS' : null,"checkSign":false,"initialDescStrProps":null,"labelAlert":_vm.photoDetails.LabelLevel === 1
            ? 3 : _vm.photoDetails.LabelLevel === 2
            ? 1 :  _vm.photoDetails.LabelLevel === 3
            ? 0 : null}})]],2)],1),_c('div',{staticClass:"th-body--label"},[_c('div',{staticClass:"th-body--label--first"},[_c('p',[_vm._v(" "+_vm._s(_vm.$t('Label', _vm.currentSelectedLanguage))+" ")]),_c('div',{staticClass:"round-div"},[_c('div',{staticClass:"th-body--label--first-round gray",on:{"click":function($event){return _vm.changeLabel(0)}}},[(!_vm.currentLabel)?_c('b-icon',{attrs:{"icon":"check2"}}):_vm._e()],1),_c('div',{staticClass:"th-body--label--first-round green",on:{"click":function($event){return _vm.changeLabel(1)}}},[(_vm.currentLabel===1)?_c('b-icon',{attrs:{"icon":"check2"}}):_vm._e()],1),_c('div',{staticClass:"th-body--label--first-round yellow",on:{"click":function($event){return _vm.changeLabel(2)}}},[(_vm.currentLabel === 2)?_c('b-icon',{attrs:{"icon":"check2"}}):_vm._e()],1),_c('div',{staticClass:"th-body--label--first-round red",on:{"click":function($event){return _vm.changeLabel(3)}}},[(_vm.currentLabel === 3)?_c('b-icon',{attrs:{"icon":"check2"}}):_vm._e()],1)])]),_c('sbmt-btn',{staticStyle:{"text-transform":"uppercase"},attrs:{"buttonText":_vm.$t('Delete Image', _vm.currentSelectedLanguage)},on:{"buttonEventEmit":function($event){return _vm.deleteImage()}}})],1),_c('div',{staticClass:"th-body--comment"},[_c('p',[_vm._v(" "+_vm._s(_vm.$t('Comment', _vm.currentSelectedLanguage))+" ")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.comment),expression:"comment"}],domProps:{"value":(_vm.comment)},on:{"input":function($event){if($event.target.composing)return;_vm.comment=$event.target.value}}}),_vm._v(" "),_c('sbmt-btn',{attrs:{"buttonText":_vm.$t('Save', _vm.currentSelectedLanguage)},on:{"buttonEventEmit":function($event){return _vm.saveData()}}})],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }