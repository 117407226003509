<template>
  <div class="tb-photo" v-if="photoDetails">
    <PatientPhotoHeader/>
    <PatientPhoto/>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import PatientPhoto
  from '../../../../../components/moleculs/dashboard/patient/pageBody/visit/patientPhoto.vue';
import PatientPhotoHeader
  from '../../../../../components/moleculs/dashboard/patient/pageHead/patientPhotoHeader.vue';

export default {
  name: 'patientPagePhotoDetail',
  components: {PatientPhotoHeader, PatientPhoto},
  async beforeMount() {
    this.setClass(true);
    //await this.$store.dispatch(GET_PHOTO_DETAILS, {photoId: this.$route.params.imageId})
    //this.photoDetailsLocal = this.photoDetails;
  },
  watch: {
    '$route': {
      handler: function (route) {
        if (!route.params.allowOnlyWithPush) {
          this.$router.push({
            name: 'doctorPatientpageDetail',
            params: {patientId: this.$route.params.patientId}
          });
        }
      },
      deep: true,
      immediate: true
    },
  },
  computed: {
    ...mapGetters({
      patient: 'getSelectedPatient',
      photoDetails: 'getSelectedPhotoDetail',
      visit: 'getPatientVisitDetail',
      initialData: 'getPatientInitialData',
    }),
  },
  methods: {
    setClass(argument) {
      let element = document.getElementsByClassName('th-dash');
      if (element.length > 0 && argument) {
        element[0].classList.add('remove-height')
      } else if (element.length > 0 && !argument) {
        element[0].classList.remove('remove-height')
      }
    }
  },
  beforeDestroy() {
    this.setClass(false)
  }
};
</script>

<style lang="scss" scoped>
.tb-photo {
  padding: 32px 32px 26px 30px;
  background: $snow-white;
  display: flex;
  flex-direction: column;
}
</style>
