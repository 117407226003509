<template>
  <div class="photoheader" v-if="initialData&&initialData.PatientInfo">
    <div class="th-photo---child">
      <div class="th-photo---child--page">
        <b-link @click="backToPreviousPage">
          <b-icon icon="arrow-left"></b-icon>
        </b-link>
        <h2>
          {{ $t('Patient', currentSelectedLanguage) }}
        </h2>
        <h2 v-if="initialData.PatientInfo.ListNumber">
          {{ initialData.PatientInfo.ListNumber }}
        </h2>
        <h2 v-if="initialData.PatientInfo.ClinicAccountId">
          {{ initialData.PatientInfo.ClinicAccountId }}
        </h2>
        <h2 v-if="initialData.PatientInfo.Id">
          {{ initialData.PatientInfo.Id }}
        </h2>
        <b-link class="arrow-right">
          <b-icon icon="chevron-right"></b-icon>
        </b-link>
        <h2 v-if="photoPosition">
          {{ photoPosition }}
        </h2>
      </div>
      <div class="th-photo---child--drinfo">
        <p class="text-right">
          {{ userProfile.Name }}
        </p>
        <p class="text-right">
          {{ userProfile.ClinicName }}
        </p>
      </div>
    </div>
    <div class="th-photo---child">
      <div class="th-photo---child--fileinfo">
        <p v-if="photoDetails && photoDetails.FileName">
          {{ `File: ${photoDetails.FileName}` }}
        </p>
        <p v-if="initialData.PatientVisitsInfo && initialData.PatientVisitsInfo.length>0">
          {{ `USER: ${initialData.PatientVisitsInfo[0].UserName}` }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex';

export default {
  name: 'patientPhotoHeader',
  computed: {
    ...mapState(
      {
        currentSelectedLanguage: (state) => state.currentSelectedLanguage,
      },
    ),
    ...mapGetters({
      userProfile: 'getProfile',
      patient: 'getSelectedPatient',
      initialData: 'getPatientInitialData',
      photoDetails: 'getSelectedPhotoDetail',
    }),
    photoPosition() {
      if (this.photoDetails !== null) {
        if (this.photoDetails.Position === 1) {
          return 'General Front'
        }
        if (this.photoDetails.Position === 2) {
          return 'Left Side';
        }
        if (this.photoDetails.Position === 3) {
          return 'Right Side';
        }
      }
      return null;
    }
  },
  methods: {
    ...mapActions({
      clearPhotoDetails: 'clearPhotoDetails',
    }),
    async backToPreviousPage() {
      //await this.$router.back();
      await this.$router.push({
        name: 'doctorPatientpageDetail',
        params: {patientId: this.$route.params.patientId}
      });
    },
  },
  async beforeDestroy(){
    await this.clearPhotoDetails();
  }
};
</script>

<style lang="scss" scoped>
.th-photo {
  display: flex;
  flex-direction: column;

  &---child {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &:first-child {
      margin-bottom: 16px;
    }

    &--page {
      display: flex;
      flex-direction: row;
      align-items: center;

      & > a {
        font-size: 21px;
        color: $charade;
        outline: 0;

        &:last-child {
          height: 15px;
        }
      }

      & > .arrow-right {
        display: flex;

        svg {
          height: 12px;
        }
      }

      & > h2 {
        @include font-source($openSans, 18px, $neo-future, 600, normal, normal);
        @include space($type: margin, $direction: bottom, $amount: 0);

        &:nth-child(2) {
          @include space($type: margin, $direction: left, $amount: 21px);
        }

        &:not(:nth-child(2)) {
          @include space($type: margin, $direction: left, $amount: 6px);
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 250px;
        }

        &:last-child {
          color: $neo-future;
        }
      }
    }

    &--drinfo {
      @include space($type: margin, $direction: left, $amount: auto);

      & > p {
        margin-bottom: 0;
        text-transform: capitalize;

        &:first-child {
          @include font-source($openSans, 12px, $secondary, 600, normal, normal);
        }

        &:last-child {
          @include font-source($openSans, 11px, rgba(30, 45, 62, 0.6), 600, normal, normal);
        }
      }
    }

    &--fileinfo {
      & > p {
        @include font-source($openSans, 16px, $primary, normal, 22px, normal);
        margin-bottom: 0;
      }
    }
  }
}

.photoheader {
  margin-bottom: 29px;
}
</style>
