<template>
  <div class="th-body" v-if="photoDetails">
    <modal
      name="singlePictureModal"
      width="100%"
      height="100%"
      classes="picture-wrp">
      <div slot="top-right">
        <b-icon
          class="closeBtn"
          icon="x"
          @click="$modal.hide('singlePictureModal')"
        />
      </div>
      <swiper class="swiper" :options="swiperOption">
        <div class="th-visit-wrp--child---xray special">
          <swiper-slide>
            <image-portrait
              :showFullscreenButton="false"
              :propsUrl="photoDetails.Base64Photo">
              <template>
                <round-label-with-check
                  class="th-visit-wrp--child---xray-lbl"
                  :initialStrProps="photoDetails.Position === 1
              ? 'GF' : photoDetails.Position === 2
              ? 'LS' : photoDetails.Position === 3
              ? 'RS' : null"
                  :checkSign="false"
                  :initialDescStrProps="null"
                  :labelAlert="photoDetails.LabelLevel === 1
              ? 3 : photoDetails.LabelLevel === 2
              ? 1 :  photoDetails.LabelLevel === 3
              ? 0 : null"
                />
              </template>
            </image-portrait>
          </swiper-slide>
        </div>
        <div class="swiper-scrollbar" slot="scrollbar"></div>
      </swiper>
    </modal>
    <div class="th-body--img">
      <image-portrait
        class="special-arrow-case"
        :propsphotoId="photoId"
        :propsUrl="photoDetails.Base64Photo"
        :enableZoomEffect="true"
        @fullScreenImageEmit="callFscreen"
      >
        <template>
          <round-label-with-check
            class="th-visit-wrp--child---xray-lbl"
            :initialStrProps="photoDetails.Position === 1
              ? 'GF' : photoDetails.Position === 2
              ? 'LS' :  photoDetails.Position === 3
              ? 'RS' : null"
            :checkSign="false"
            :initialDescStrProps="null"
            :labelAlert="photoDetails.LabelLevel === 1
              ? 3 : photoDetails.LabelLevel === 2
              ? 1 :  photoDetails.LabelLevel === 3
              ? 0 : null"
          />
        </template>
      </image-portrait>
    </div>
    <div class="th-body--label">
      <div class="th-body--label--first">
        <p>
          {{ $t('Label', currentSelectedLanguage) }}
        </p>
        <div class="round-div">
          <div class="th-body--label--first-round gray" @click="changeLabel(0)">
            <b-icon
              v-if="!currentLabel"
              icon="check2"
            />
          </div>
          <div class="th-body--label--first-round green" @click="changeLabel(1)">
            <b-icon
              v-if="currentLabel===1"
              icon="check2"
            />
          </div>
          <div class="th-body--label--first-round yellow" @click="changeLabel(2)">
            <b-icon
              v-if="currentLabel === 2"
              icon="check2"
            />
          </div>
          <div class="th-body--label--first-round red" @click="changeLabel(3)">
            <b-icon
              v-if="currentLabel === 3"
              icon="check2"
            />
          </div>
        </div>
      </div>
      <sbmt-btn
        style="text-transform: uppercase"
        :buttonText="$t('Delete Image', currentSelectedLanguage)"
        @buttonEventEmit="deleteImage()"
      />
    </div>
    <div class="th-body--comment">
      <p>
        {{ $t('Comment', currentSelectedLanguage) }}
      </p>
      <textarea v-model="comment"/>
      <sbmt-btn
        @buttonEventEmit="saveData()"
        :buttonText="$t('Save', currentSelectedLanguage)"
      />
    </div>
  </div>
</template>

<script>
import {mapState, mapGetters} from 'vuex';
import {Swiper, SwiperSlide} from 'vue-awesome-swiper';
import ImagePortrait from '../../../../../atoms/imagePortrait.vue';
import RoundLabelWithCheck from '../../../../../atoms/roundLabelWithCheck.vue';
import sbmtBtn from '../../../../../atoms/button.vue';
import {EDIT_PHOTO_DETAILS, GET_PHOTO_DETAILS} from '../../../../../../store/modules/photos.module';
import {SET_MODAL_ACTION} from '../../../../../../store';
import 'swiper/swiper.scss';

export default {
  name: 'patientPhoto',
  data() {
    return {
      comment: null,
      currentLabel: null,
      photoId: null,
      swiperOption: {
        spaceBetween: 30,
        scrollbar: {
          el: '.swiper-scrollbar',
        },
      },
      coordinates: []
    };
  },
  watch: {
    '$route': {
      handler: function (route) {
        if (route.params.imageId !== 'undefined') {
          this.photoId = parseInt(route.params.imageId);
        }
      },
      deep: true,
      immediate: true
    },
  },
  async beforeMount() {
    await this.$store.dispatch(GET_PHOTO_DETAILS, {photoId: this.$route.params.imageId})
    this.comment = this.photoDetails?.Comment;
    this.currentLabel = this.photoDetails?.LabelLevel;
  },
  computed: {
    ...mapState(
      {
        currentSelectedLanguage: (state) => state.currentSelectedLanguage,
      },
    ),
    ...mapGetters(
      {
        photoDetails: 'getSelectedPhotoDetail',
      },
    ),
  },
  components: {
    ImagePortrait,
    RoundLabelWithCheck,
    sbmtBtn,
    Swiper,
    SwiperSlide,
  },
  methods: {
    async deleteImage() {
      await this.$store.dispatch(SET_MODAL_ACTION, {
        current: true,
        type: 'delete'
      })
    },
    callFscreen() {
      this.$modal.show('singlePictureModal');
    },
    changeLabel(value) {
      this.$emit('changeLabel');
      this.currentLabel = value;
    },
    async saveData() {
      this.$emit('emitButtonCall');
      await this.$store.dispatch(EDIT_PHOTO_DETAILS, {
        photoId: this.photoId,
        comment: this.comment ? this.comment : '',
        labelLevel: this.currentLabel ? this.currentLabel : 0,
      });
    }
  },
}
</script>

<style lang="scss" scoped>
.th-body {
  &--img {
    max-height: 1026px;
    max-width: 780px;
    margin-bottom: 68px;

    .th-img-port-wrp {
      height: 100%;
      width: 100%;
    }
  }

  &--label {
    display: flex;
    flex-direction: row;
    margin-bottom: 30px;

    &--first {
      display: flex;
      flex-direction: column;

      & > p {
        text-transform: uppercase;
        margin-bottom: 16px;
        @include font-source($openSans, 12px, $primary, 600, normal, normal);
      }

      .round-div {
        display: flex;
        flex-direction: row;
      }

      &-round {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        cursor: pointer;
        border: 1px solid rgba(30, 45, 62, 0.1);
        align-items: center;
        justify-content: center;
        display: flex;

        & > svg {
          font-size: 45px;
          color: $white;
        }

        &:not(:last-child) {
          margin-right: 30px;
        }

        &:last-child {
          margin-right: 60px;
        }
      }
    }

    &--last {
      display: flex;
      flex-direction: column;

      p {
        margin-bottom: 16px;
        text-transform: uppercase;
        @include font-source($openSans, 12px, $primary, 600, normal, normal);
      }

      &---checkbox {
        color: $blood-bright;
        width: 60px;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        box-shadow: inset 0 1px 3px 1px rgba(30, 45, 62, 0.1);
        background-color: $white;
        cursor: pointer;

        & > svg {
          font-size: 45px;
        }
      }
    }

    & > button {
      margin-left: auto;
      margin-top: auto;
    }
  }

  &--comment {
    display: flex;
    flex-direction: column;

    & > p {
      @include font-source($openSans, 12px, $primary, 600, normal, normal);
      margin-bottom: 16px;
      text-transform: uppercase;
    }

    & > textarea {
      padding: 17px;
      border-radius: 10px;
      box-shadow: inset 0 1px 3px 1px rgba(30, 45, 62, 0.1);
      background-color: $white;
      resize: none;
      border: none;
      margin-bottom: 40px;
      @include font-source($openSans, 16px, $primary, normal, normal, 0.4px);

      &:focus {
        outline: 0;
      }
    }

    & > button {
      width: 122px;
      height: 45px;
      text-transform: uppercase;
    }
  }

  .th-img-port-wrp---fullscreen {
    background-color: #81c8bd;
  }
}
</style>
